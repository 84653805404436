<template>
	<div class="hx-order-six">
		
			
				<div class="left" align="left">
					<img src="../../assets/ysmimg/ysmorderoneimg.png" width="90px" height="110px" style="vertical-align: middle;" >
					<div>
						<h3>上传口内照片</h3>
						<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;">请在此处上传口内图像。</div>
						<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;">您可以在<a href="./hxkn.pdf" target="_blank" style="color: #0D8EA4;font-weight: bold;text-decoration: underline;">此处</a>找到有关图片的示例。</div>
						<div align="left">
							<el-form :model="ruleForm"  ref="ruleForm">
								<el-form-item prop="chengxiangDate" label="成像日期">
								  <el-date-picker :picker-options="filerData.pickerOptions" type="date" placeholder="成像日期" v-model="ruleForm.chengxiangDate" style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy/MM/dd"></el-date-picker>		     
								</el-form-item>
							</el-form>
							
						</div>
						<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bold;">注意</span>：&nbsp;图像需近30天内</div>
						<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bold;">有效格式</span>：.jpg、.jpeg、png、.tif、.tiff</div>
						<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bold;">最小文件大小</span>：500KB</div>
						<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bold;">最大文件大小</span>：11MB</div>
					</div>
				</div>
				
			
			
				<div class="right">
					<!-- 请上传左侧 -->
					<div class="shanghe"  style="display: inline-block;margin-right: 0.625rem;">
						<div style="position: relative;left: -7.25rem;">左侧</div>
						<el-upload
						  class="upload-demo"
						  drag
						  action=''
						  ref="upload"
						  :http-request="changeFile"
						  multiple
						  style="height: 200px;margin-bottom: 30px;"
						  >
						  <img v-show="zcVisiable" class="zc" :src="zcPicUrl" style="width: 100%; height: 100%;" />
						  <div v-show="zuoceVisiable" style="width: 100%; min-height: 30px;line-height: 30px;position: absolute;top: 0px;background-color: #0D8EA4;color: white;word-break: break-all;" >{{zuoceMess}}</div>
						  <img style="margin-bottom: 10px;margin-top: 20px;" src="../../assets/kounei/kouneileft.png" width="120" height="120" />
						  <div class="el-upload__text">将文件拖到此处或<em>点击上传</em><br/>如修改请重新点击上传</div>
						  <div class="el-upload__tip" slot="tip"></div>
						</el-upload>
						
					</div>
					<!-- 请上传右侧 -->
					<div class="xiahe"   style="display: inline-block;">
					<div style="position: relative;left: -7.25rem;">右侧</div>
					<el-upload
					  class="upload-demo1"
					  drag
					  action=''
					  multiple
					  :http-request="changeFile1"
					  
					  >
					   <img v-show="ycVisiable" class="yc" :src="ycPicUrl" style="width: 100%; height: 100%;" />
					   <div v-show="youceVisiable" style="width: 100%; min-height: 30px;line-height: 30px;position: absolute;top: 0px;background-color: #0D8EA4;color: white;word-break: break-all;" >{{youceMess}}</div>
					  <img style="margin-bottom: 10px;margin-top: 20px;" src="../../assets/kounei/kouneiright.png"  width="120" height="120" />
					  <div class="el-upload__text">将文件拖到此处或<em>点击上传</em><br/>如修改请重新点击上传</div>
					  <div class="el-upload__tip" slot="tip"></div>
					</el-upload>
					</div>
					<br />
					<!-- 正面 -->
					<div class="yaohe"  style="display: inline-block;margin-right: 0.625rem;">
					<div style="position: relative;left: -7.25rem;">正面</div>
					<el-upload
					  class="upload-demo1"
					  drag
					  action=''
					  multiple
					  :http-request="changeFile2"
					  style="margin-bottom: 30px;"
					  >
					  <img v-show="zmVisiable" class="zm" :src="zmPicUrl" style="width: 100%; height: 100%;" />
					   <div v-show="zhengmianVisiable" style="width: 100%; min-height: 30px;line-height: 30px;position: absolute;top: 0px;background-color: #0D8EA4;color: white;word-break: break-all;" >{{zhengmianMess}}</div>
						<img style="margin-bottom: 10px;margin-top: 20px;" src="../../assets/kounei/kouneizheng.png" width="120" height="120" />
					  <div class="el-upload__text">将文件拖到此处或<em>点击上传</em><br/>如修改请重新点击上传</div>
					  <div class="el-upload__tip" slot="tip"></div>
					</el-upload>
					</div>
					<!-- 上颌 -->
					<div class="yaohe"   style="display: inline-block;">
					<div style="position: relative;left: -7.25rem;">上颌</div>
					<el-upload
					  class="upload-demo1"
					  drag
					  action=''
					  multiple
					  :http-request="changeFile3"
					  >
					  <img v-show="shVisiable" class="sh" :src="shPicUrl" style="width: 100%; height: 100%;" />
					  <div v-show="shangheVisiable" style="width: 100%; min-height: 30px;line-height: 30px;position: absolute;top: 0px;background-color: #0D8EA4;color: white;word-break: break-all;" >{{shangheMess}}</div>
					  <img style="margin-bottom: 10px;margin-top: 20px;" src="../../assets/kounei/kouneishang.png"  width="120" height="120" />
					  <div class="el-upload__text">将文件拖到此处或<em>点击上传</em><br/>如修改请重新点击上传</div>
					  <div class="el-upload__tip" slot="tip"></div>
					</el-upload>
					</div>
					<!-- 下颌 -->
					<div class="yaohe"  style="position: relative;width: 270px;padding-left: 0px;">
					<div style="position: relative;" align="left">下颌</div>
					<el-upload
					  class="upload-demo1"
					  drag
					  action=''
					  multiple
					  :http-request="changeFile4"
					  style="position: relative;margin-bottom: 30px;"
					  >
					   <img v-show="xhVisiable" class="xh" :src="xhPicUrl" style="width: 100%; height: 100%;" />
					   <div v-show="xiaheVisiable" style="width: 100%; min-height: 30px;line-height: 30px;position: absolute;top: 0px;background-color: #0D8EA4;color: white;word-break: break-all;" >{{xiaheMess}}</div>
					   <img style="margin-bottom: 10px;margin-top: 20px;" src="../../assets/kounei/kouneixia.png" width="120" height="120" />
					  <div class="el-upload__text">将文件拖到此处或<em>点击上传</em><br/>如修改请重新点击上传</div>
					  <div class="el-upload__tip" slot="tip"></div>
					</el-upload>
					</div>
					<div align="right">
						<el-button @click="back" class="back"  type="button">返回</el-button>
						<el-button @click="nextStepKN" class="nextStep"  type="button">下一步</el-button>
					</div>
				</div>
		
	</div>
</template>

<script>
	export default{
		data:function(){
			return{
				filerData: {
				  pickerOptions: {
				    disabledDate(time) {
				      return time.getTime() > Date.now() - 8.64e6
				    }
				  },
				},
				zuoceVisiable:false,
				youceVisiable:false,
				zhengmianVisiable:false,
				shangheVisiable:false,
				xiaheVisiable:false,
				zuoceMess:'',
				youceMess:'',
				zhengmianMess:'',
				shangheMess:'',
				xiaheMess:'',
				zcUpload:false,
				ycUpload:false,
				zmUpload:false,
				shUpload:false,
				xhUpload:false,
				
				zcVisiable:false,
				ycVisiable:false,
				zmVisiable:false,
				shVisiable:false,
				xhVisiable:false,
				zcPicUrl:'',
				ycPicUrl:'',
				zmPicUrl:'',
				shPicUrl:'',
				xhPicUrl:'',
				ruleForm:{
					chengxiangDate:'',
					oid:'',
				},
				
				
			}
		},
		methods:{
				//上传左侧
			changeFile(file){
				if(this.ycUpload==true||this.zmUpload==true||this.shUpload==true||this.xhUpload==true){
					this.$alert("已有文件上传中,请稍候!");
					return;
				}
				this.zcUpload=true;
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'knZuo')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.zuoceVisiable=false;
				   /* this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
				   		
						if(res.data.code==200){
							this.zcPicUrl=res.data.data.picUrl;
							this.zcVisiable=true;
							this.$message.success("上传成功");
						}
				   	}).catch(err=>{
				   		this.$alert("系统出现异常,请联系管理员!");
				   	}); */
					this.$axios({
					                    method:'post',
					                    headers: {
					                        "Content-Type": "multipart/form-data"
					                    },
					                    url:'client/order/uploadResourceFile',//自定义上传url
					                    data:formData,
					                    onUploadProgress: progressEvent => {
											
											 if (progressEvent.lengthComputable) {
											                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
											                        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
											                     let percent=(progressEvent.loaded / progressEvent.total * 100) | 0
											                     file.onProgress({percent:percent});//调用uploader的进度回调
																 if(percent==100){
																 		this.zuoceMess="文件已上传至服务器,资源解析中......";
																 		this.zuoceVisiable=true;
																 }
											                    }
					                         
					                    }
					                }).then((res)=>{
					                    if(res.data.code==200){
											this.zcUpload=false;
											 let pros=document.getElementsByClassName("el-progress el-progress--line");
											for(let i=0;i<pros.length;i++){
												pros[i].style.display="none";
											} 
											let fName=res.data.data.picUrl.substring(27,res.data.data.picUrl.length);
											 this.zuoceMess="资源上传解析完毕："+res.data.data.fileName;
											 var houZhui=res.data.data.picUrl.substring(res.data.data.picUrl.lastIndexOf('.')+1);
											 console.log(houZhui);
											 if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
												 this.zcVisiable=true;
												 this.zcPicUrl=res.data.data.picUrl;
											 }else{
												  this.zcPicUrl="";
												  this.zcVisiable=false;
											 }
					                    
											this.$message({
												message:"上传成功",
												type:"success",
												duration:900
											});
											
					                    }else{
					                        this.$message.error('上传失败');
					                    }
					                }).catch((err)=>{
					                    this.$message.error(err);
					                })
			},
			//上传右侧
			changeFile1(file){
				if(this.zcUpload==true||this.zmUpload==true||this.shUpload==true||this.xhUpload==true){
					this.$alert("已有文件上传中,请稍候!");
					return;
				}
				this.ycUpload=true;
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'knYou')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				this.youceVisiable=false;
				  /* this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
						if(res.data.code==200){
							this.$message.success("上传成功");
							this.ycPicUrl=res.data.data.picUrl;
							this.ycVisiable=true;
						}
				   	}).catch(err=>{
				   		this.$alert("系统出现异常,请联系管理员!");
				   	}); */
					this.$axios({
					                    method:'post',
					                    headers: {
					                        "Content-Type": "multipart/form-data"
					                    },
					                    url:'client/order/uploadResourceFile',//自定义上传url
					                    data:formData,
					                    onUploadProgress: progressEvent => {
											
											 if (progressEvent.lengthComputable) {
											                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
											                        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
											                     let percent=(progressEvent.loaded / progressEvent.total * 100) | 0
											                     file.onProgress({percent:percent});//调用uploader的进度回调
																 if(percent==100){
																 		this.youceMess="文件已上传至服务器,资源解析中......";
																 		this.youceVisiable=true;
																 }
											                    }
					                         
					                    }
					                }).then((res)=>{
					                    if(res.data.code==200){
											this.ycUpload=false;
											 let pros=document.getElementsByClassName("el-progress el-progress--line");
											for(let i=0;i<pros.length;i++){
												pros[i].style.display="none";
											} 
											let fName=res.data.data.picUrl.substring(27,res.data.data.picUrl.length);
											 this.youceMess="资源上传解析完毕："+res.data.data.fileName;
											 var houZhui=res.data.data.picUrl.substring(res.data.data.picUrl.lastIndexOf('.')+1);
											 console.log(houZhui);
											 if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
												 this.ycVisiable=true;
												 this.ycPicUrl=res.data.data.picUrl;
											 }else{
												  this.ycPicUrl="";
												  this.ycVisiable=false;
											 }
					                    
											this.$message({
												message:"上传成功",
												type:"success",
												duration:900
											});
											
					                    }else{
					                        this.$message.error('上传失败');
					                    }
					                }).catch((err)=>{
					                    this.$message.error(err);
					                })
			},
			//上传正面
			changeFile2(file){
				if(this.zcUpload==true||this.ycUpload==true||this.shUpload==true||this.xhUpload==true){
					this.$alert("已有文件上传中,请稍候!");
					return;
				}
				this.zmUpload=true;
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'knZheng')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.zhengmianVisiable=false;
				   /* this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
						if(res.data.code==200){
							this.$message.success("上传成功");
							this.zmPicUrl=res.data.data.picUrl;
							this.zmVisiable=true;
						}
				   	}).catch(err=>{
				   		this.$alert("系统出现异常,请联系管理员!");
				   	}); */
					this.$axios({
					                    method:'post',
					                    headers: {
					                        "Content-Type": "multipart/form-data"
					                    },
					                    url:'client/order/uploadResourceFile',//自定义上传url
					                    data:formData,
					                    onUploadProgress: progressEvent => {
											
											 if (progressEvent.lengthComputable) {
											                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
											                        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
											                     let percent=(progressEvent.loaded / progressEvent.total * 100) | 0
											                     file.onProgress({percent:percent});//调用uploader的进度回调
																 if(percent==100){
																 		this.zhengmianMess="文件已上传至服务器,资源解析中......";
																 		this.zhengmianVisiable=true;
																 }
											                    }
					                         
					                    }
					                }).then((res)=>{
					                    if(res.data.code==200){
											this.zmUpload=false;
											 let pros=document.getElementsByClassName("el-progress el-progress--line");
											for(let i=0;i<pros.length;i++){
												pros[i].style.display="none";
											} 
											let fName=res.data.data.picUrl.substring(27,res.data.data.picUrl.length);
											 this.zhengmianMess="资源上传解析完毕："+res.data.data.fileName;
											 var houZhui=res.data.data.picUrl.substring(res.data.data.picUrl.lastIndexOf('.')+1);
											 console.log(houZhui);
											 if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
												 this.zmVisiable=true;
												 this.zmPicUrl=res.data.data.picUrl;
											 }else{
												  this.zmPicUrl="";
												  this.zmVisiable=false;
											 }
					                    
											this.$message({
												message:"上传成功",
												type:"success",
												duration:900
											});
											
					                    }else{
					                        this.$message.error('上传失败');
					                    }
					                }).catch((err)=>{
					                    this.$message.error(err);
					                })
			},
			//上颌
			changeFile3(file){
				if(this.zcUpload==true||this.zmUpload==true||this.ycUpload==true||this.xhUpload==true){
					this.$alert("已有文件上传中,请稍候!");
					return;
				}
				this.shUpload=true;
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'knShang')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.shangheVisiable=false;
				   /* this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
						if(res.data.code==200){
							this.$message.success("上传成功");
							this.shPicUrl=res.data.data.picUrl;
							this.shVisiable=true;
						}
				   	}).catch(err=>{
				   		this.$alert("系统出现异常,请联系管理员!");
				   	}); */
					this.$axios({
					                    method:'post',
					                    headers: {
					                        "Content-Type": "multipart/form-data"
					                    },
					                    url:'client/order/uploadResourceFile',//自定义上传url
					                    data:formData,
					                    onUploadProgress: progressEvent => {
											
											 if (progressEvent.lengthComputable) {
											                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
											                        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
											                     let percent=(progressEvent.loaded / progressEvent.total * 100) | 0
											                     file.onProgress({percent:percent});//调用uploader的进度回调
																 if(percent==100){
																 		this.shangheMess="文件已上传至服务器,资源解析中......";
																 		this.shangheVisiable=true;
																 }
											                    }
					                         
					                    }
					                }).then((res)=>{
					                    if(res.data.code==200){
											this.shUpload=false;
											 let pros=document.getElementsByClassName("el-progress el-progress--line");
											for(let i=0;i<pros.length;i++){
												pros[i].style.display="none";
											} 
											let fName=res.data.data.picUrl.substring(27,res.data.data.picUrl.length);
											 this.shangheMess="资源上传解析完毕："+res.data.data.fileName;
											 var houZhui=res.data.data.picUrl.substring(res.data.data.picUrl.lastIndexOf('.')+1);
											 console.log(houZhui);
											 if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
												 this.shVisiable=true;
												 this.shPicUrl=res.data.data.picUrl;
											 }else{
												  this.shPicUrl="";
												  this.shVisiable=false;
											 }
					                    
											this.$message({
												message:"上传成功",
												type:"success",
												duration:900
											});
											
					                    }else{
					                        this.$message.error('上传失败');
					                    }
					                }).catch((err)=>{
					                    this.$message.error(err);
					                })
					
			},
			//下颌
			changeFile4(file){
				if(this.zcUpload==true||this.zmUpload==true||this.ycUpload==true||this.shUpload==true){
					this.$alert("已有文件上传中,请稍候!");
					return;
				}
				this.xhUpload=true;
				      let formData = new FormData()
				      formData.append('file', file.file)// 传文件
				      formData.append('oid', this.ruleForm.oid)
					  formData.append('type', 'knXia')
				      let self = this
				   let config = { headers: { "Content-Type": "multipart/form-data" }};
				   this.xiaheVisiable=false;
				   /* this.$axios.post('client/order/uploadResourceFile', formData,config)
				       .then(res=>{
						if(res.data.code==200){
							this.$message.success("上传成功");
							this.xhPicUrl=res.data.data.picUrl;
							this.xhVisiable=true;
						}
				   	}).catch(err=>{
				   		this.$alert("系统出现异常,请联系管理员!");
				   	}); */
					this.$axios({
					                    method:'post',
					                    headers: {
					                        "Content-Type": "multipart/form-data"
					                    },
					                    url:'client/order/uploadResourceFile',//自定义上传url
					                    data:formData,
					                    onUploadProgress: progressEvent => {
											
											 if (progressEvent.lengthComputable) {
											                        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
											                        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
											                     let percent=(progressEvent.loaded / progressEvent.total * 100) | 0
											                     file.onProgress({percent:percent});//调用uploader的进度回调
																 if(percent==100){
																 		this.xiaheMess="文件已上传至服务器,资源解析中......";
																 		this.xiaheVisiable=true;
																 }
											                    }
					                         
					                    }
					                }).then((res)=>{
					                    if(res.data.code==200){
											this.xhUpload=false;
											 let pros=document.getElementsByClassName("el-progress el-progress--line");
											for(let i=0;i<pros.length;i++){
												pros[i].style.display="none";
											} 
											let fName=res.data.data.picUrl.substring(27,res.data.data.picUrl.length);
											 this.xiaheMess="资源上传解析完毕："+res.data.data.fileName;
											 var houZhui=res.data.data.picUrl.substring(res.data.data.picUrl.lastIndexOf('.')+1);
											 console.log(houZhui);
											 if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
												 this.xhVisiable=true;
												 this.xhPicUrl=res.data.data.picUrl;
											 }else{
												  this.xhPicUrl="";
												  this.xhVisiable=false;
											 }
					                    
											this.$message({
												message:"上传成功",
												type:"success",
												duration:900
											});
											
					                    }else{
					                        this.$message.error('上传失败');
					                    }
					                }).catch((err)=>{
					                    this.$message.error(err);
					                })
					
			},
			//下一步
			nextStepKN(){
				//左侧
				if(this.zcPicUrl!=''||this.ycPicUrl!=''||this.zmPicUrl!=''||this.shPicUrl!=''||this.xhPicUrl!=''){
					if(this.ruleForm.chengxiangDate==null||this.ruleForm.chengxiangDate==''){
						this.$alert("请填写成像日期","提示");
						return;
					}
				}
				if(this.ruleForm.chengxiangDate!=null&&this.ruleForm.chengxiangDate!=''){
					/* if(this.zcPicUrl==''){
						this.$alert("请上传左侧图片","提示");
						return;
					}else if(this.ycPicUrl==''){
						this.$alert("请上传右侧图片","提示");
						return;
					}else if(this.zmPicUrl==''){
						this.$alert("请上传正面图片","提示");
						return;
					}else if(this.shPicUrl==''){
						this.$alert("请上传上颌图片","提示");
						return;
					}
					else if(this.xhPicUrl==''){
						this.$alert("请上传下颌图片","提示");
						return;
					} */
					if(this.zcPicUrl!=''||this.ycPicUrl!=''||this.zmPicUrl!=''||this.shPicUrl!=''||this.xhPicUrl!=''){
						
					}else{
						this.$alert("请上传图片","提示");
						return;
					}
				}
				
				//上传日期
				let formData1 = new FormData()
				formData1.append('date',this.ruleForm.chengxiangDate)// 传文件
				formData1.append('oid', this.ruleForm.oid)
				formData1.append('type',"knDate")
			
				this.$axios.post("client/order/uploadResourceDate",formData1,{
					headers:{
						"Content-type":"application/json"
					}
				}).then(res=>{
					if(res.data.code==200){
						this.$message({
							message:"添加成像日期成功",
							type:"success",
							duration:900
						});
						    //设置返回路径
						    this.$store.commit("setBackLocation", "/hxmain/hxorder/hxordersix");
							this.$store.commit('setHxStepIndex', 7);
						    this.$router.push("/hxmain/hxorder/hxorderseven");
					}
				}).catch(err=>{
					this.$message({
						message:"请联系管理员",
						type:"error",
						duration:900
					});
				});
				
			},
			//返回
			back(){
				this.$store.commit("setHxStepIndex",5);
				this.$router.push("/hxmain/hxorder/hxorderfive");
			},
		},
		mounted:function(){
				this.$store.commit('setHxStepIndex', 6);
			//设置默认地址
			sessionStorage.setItem("location","/hxmain/hxorder/hxordersix")
			//获取当前添加的订单oid
			//获取oid
			let oid=sessionStorage.getItem("oid");
			// if(oid==null){
			// 	this.$alert("请填写患者信息","提示");
			// 	this.$router.push("/addorder/orderone");
			// }else{
			// 	this.ruleForm.oid=oid;
			// }
			//设置返回路径
			this.$store.commit("setBackLocation","/hxmain/hxorder/hxorderfive");
			document.getElementsByClassName("saveb")[0].setAttribute("class","saveb show");
			document.getElementsByClassName("print")[0].setAttribute("class","print die");
			document.getElementsByClassName("fh")[0].setAttribute("class","fh show");
		},
		created:function(){
			
			/* document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
			 document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3"); */
			//如果是编辑订单要获取oid的值
			let oid=sessionStorage.getItem("oid");
		
			if(oid!="undefined"&&oid!=''&&oid!=null){
				this.ruleForm.oid=oid;
				this.$axios.get("/client/order/getOrderInfo",{
					params:{
						oid:this.ruleForm.oid
					}
				}).then(res=>{
					let knDate=res.data.data.resource.knDate;//口内日期
					let zuo=res.data.data.resource.knZuo;//左
					let you=res.data.data.resource.knYou;//右
					let zheng=res.data.data.resource.knZheng;//正
					let shang=res.data.data.resource.knShang;//上
					let xia=res.data.data.resource.knXia;//下
					//日期回显
					if(knDate!=null){
						this.ruleForm.chengxiangDate=knDate;
					}
					//回显左
					if(zuo!=null){
						let fName=zuo.substring(27,zuo.length);
						var houZhui=zuo.substring(zuo.lastIndexOf('.')+1);
						if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
																	this.zcVisiable=true;
																	this.zcPicUrl=zuo;
																	//document.getElementsByClassName("zc")[0].setAttribute("src",zuo);
																	this.zuoceVisiable=true;
																	this.zuoceMess=fName;
						}else{
							this.zuoceVisiable=true;
							this.zuoceMess=fName;
								this.zcPicUrl="";
						}
						
						
					}
					//回显右
					if(you!=null){
						let fName=you.substring(27,you.length);
						var houZhui=you.substring(you.lastIndexOf('.')+1);
						if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
																	this.ycVisiable=true;
																	this.ycPicUrl=you;
																	document.getElementsByClassName("yc")[0].setAttribute("src",you);
																	this.youceVisiable=true;
																	this.youceMess=fName;
						}else{
							this.youceVisiable=true;
							this.youceMess=fName;
								this.ycPicUrl="";
						}
						
					}
					//回显正
					if(zheng!=null){
						let fName=zheng.substring(27,zheng.length);
						var houZhui=zheng.substring(zheng.lastIndexOf('.')+1);
						if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
																	this.zmVisiable=true;
																	this.zmPicUrl=zheng;
																	document.getElementsByClassName("zm")[0].setAttribute("src",zheng);
																	this.zhengmianVisiable=true;
																	this.zhengmianMess=fName;
						}else{
							this.zhengmianVisiable=true;
							this.zhengmianMess=fName;
								this.zmPicUrl="";
						}
						
					}
					//回显上颌
					if(shang!=null){
						let fName=shang.substring(27,shang.length);
						var houZhui=shang.substring(shang.lastIndexOf('.')+1);
						if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
																	this.shVisiable=true;
																	this.shPicUrl=shang;
																	document.getElementsByClassName("sh")[0].setAttribute("src",shang);
																	this.shangheVisiable=true;
																	this.shangheMess=fName;
						}else{
							this.shangheVisiable=true;
							this.shangheMess=fName;
								this.shPicUrl="";
						}
						
					}
					//回显下颌
					if(xia!=null){
						let fName=xia.substring(27,xia.length);
						var houZhui=xia.substring(xia.lastIndexOf('.')+1);
						if(houZhui=='jpg'||houZhui=='JPG'||houZhui=='PNG'||houZhui=='png'||houZhui=='jpeg'||houZhui=='JPEG'){
																	this.xhVisiable=true;
																	this.xhPicUrl=xia;
																	document.getElementsByClassName("xh")[0].setAttribute("src",xia);
																	this.xiaheVisiable=true;
																	this.xiaheMess=fName;
						}else{
							this.xiaheVisiable=true;
							this.xiaheMess=fName;
								this.xhPicUrl="";
						}
						
					}
				}).catch(err=>{
					this.$alert("系统出现异常,请联系管理员!");
				});
			}else{
				this.$alert('请填写患者信息', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.push("/hxmain/hxorder/hxorderone");
					}
				});
				
			}
	},
	}
</script>

<style scoped="scoped">
	.hx-order-six{
		width: 80%;
		display: flex;
		margin: 0px auto;
		margin-top: 3.125rem;
		/* padding-bottom: 5.125rem; */
		padding-bottom: 10px;
		justify-content: space-around;
	}
	
	h3{
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #0D8EA4;
		margin-bottom: 20px;
		font-size: 24px;
	}
	.left{
		display: flex;
		width: 25rem;
		position: relative;
		justify-content: flex-start;
	}
	.right{
		/* position: relative;
		left: 1.25rem; */
		width:560px;
	}
	
	.xy{
		display: none;
	}
	/* 按钮 */
	.nextStep{
		background-color: #0D8EA4 !important;
		border-color: #0D8EA4 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}
	.back{
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #0D8EA4 !important;
		color: #0D8EA4 !important;
	}
</style>
